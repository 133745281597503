import { createSelector } from 'reselect';
import { RootState } from 'types';
import { initialState } from './appSlice';

const selectDomain = (s: RootState) => s.appContainer || initialState;

export const selectNotification = createSelector(
  [selectDomain],
  ({ notification }) => notification,
);
