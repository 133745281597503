// @ts-nocheck
import axios, { AxiosError } from 'axios';
import { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { Environment } from 'global/Environment';
import { GlobalNotifications } from './../global/GlobalNotifications';
import { actions } from '../store/appSlice';
import { actions as authActions } from '../store/auth/slice';
import { store } from 'store/configureStore';

export class ResponseError extends Error {
  public error: AxiosError;

  constructor(response: AxiosError) {
    super(response.message);
    this.error = response;
  }
}

export class ApiClient {
  private static readonly baseUrl: string = Environment.API_URL;
  private static readonly apiKey: string = Environment.API_KEY;
  private static readonly instance: AxiosInstance = axios.create();

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to fetch
   * @param  {object} body The query parameters as an object
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async get(
    path: string,
    queryParams?: Object,
    options?: AxiosRequestConfig,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'GET',
      params: { ...queryParams },
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to fetch
   * @param  {BodyInit} body The payload body to post
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async post(
    path: string,
    payload?: BodyInit,
    options?: AxiosRequestConfig,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'POST',
      data: payload,
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to fetch
   * @param  {BodyInit} body The payload body to post
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async patch(
    path: string,
    payload?: BodyInit,
    options?: AxiosRequestConfig,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'PATCH',
      data: payload,
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to fetch
   * @param  {BodyInit} body The payload body to update
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async put(
    path: string,
    payload?: BodyInit,
    options?: AxiosRequestConfig,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'PUT',
      data: payload,
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to delete
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async delete(
    path: string,
    options?: AxiosRequestConfig,
    payload?: BodyInit,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'DELETE',
      data: payload,
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {AxiosResponse} response A response from a network request
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */

  static checkStatus({ status, data, config, headers }: AxiosResponse) {
    if (status === 401) {
      localStorage.removeItem('AUTH_USER');
      localStorage.removeItem('AUTH_TOKEN');
      store.dispatch(authActions.resetAuthState());
    }
    //removes id from url
    const url = config.url.replace(config.url.match(/[/][0-9]+[*/]?/g), '');

    let notificationPath =
      status === 500
        ? 'InternalServerError'
        : data
        ? data?.succeeded
          ? `${url}:${config.method}`
          : `${url}:${config.method}:${data.MessageCode}`
        : '';
    if (notificationPath === '/event/status:put' && data.data.status) {
      notificationPath = `${notificationPath}:${data.data.status}`;
    }
    const notification = GlobalNotifications.get(notificationPath);

    if (notification) {
      store.dispatch(actions.setNotification(notification));
    }

    if (status >= 200 && status < 300) {
      if (config.responseType === 'blob') {
        const _fileName = headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];

        return { fileName: _fileName, data: data };
      }

      return data.data;
    }
  }

  /**
   * Requests a URL, returning a promise
   *
   * @param  {string} path       The URL we want to request
   * @param  {AxiosRequestConfig} [options] The options we want to pass to "fetch"
   *
   * @return {object}           The response data
   */
  private static async callApi(path: string, options?: AxiosRequestConfig) {
    const requestConfig: AxiosRequestConfig = {
      method: options?.method,
      baseURL: ApiClient.baseUrl,
      url: path,
      params: options && options.params,
      data: options && options.data,
      headers: {
        ApiKey: ApiClient.apiKey,
        'Content-Type': 'application/json',
        ...(options && options.headers),
      },
      responseType:
        options && options.responseType ? options.responseType : 'json',
    };

    try {
      this.authInterceptor();
      const fetchResponse: AxiosResponse = await this.instance(requestConfig);

      return ApiClient.checkStatus(fetchResponse);
    } catch (err) {
      ApiClient.checkStatus(err.response);
      throw Error;
    }
  }

  private static authInterceptor(): void {
    this.instance.interceptors.request.use(
      config => {
        const token = localStorage.getItem('AUTH_TOKEN');
        if (token) {
          config.headers = {
            ...config.headers,
            ...{
              Authorization: `Bearer ${token}`,
            },
          };
        }
        // const { encoded } = getAuthCookie();

        // if (encoded !== '') {
        //   config.headers = {
        //     ...config.headers,
        //     ...{
        //       Authorization: `Barier ${encoded}`,
        //     },
        //   };
        // }

        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }
}
