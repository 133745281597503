export type CandidateState = {
  groupCandidates: Array<CandidateGroup>;
  candidates: ICandidate[];
  jobStatus?: string;
  filterOptions?: CandidateFilterEntry;
};

export interface GroupCandidatesParams {
  jobId: string;
  viewType: string;
}

export interface JobCandidatesParams {
  jobId: number;
  sortBy?: string;
}

export type CandidateGroup = {
  group: string;
  jobId?: number;
  jobStatus?: string;
  candidates: Array<CandidateGroupEntry>;
};

export type CandidateGroupEntry = {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  documentUrl: string;
  externalUrl: string;
  urlKey: string;
  recommendedSelf: boolean;
  internal: boolean;
  group: string;
  agentId: number;
  agentName: string;
  adminId: number;
  adminName: string;
  step: string;
  subStep: string;
  stepStatus: string | null;
  latestEvent: string;
  latestPhaseEvent: string;
  isAgent: boolean;
  inviteAsAgent: boolean;
  interview: boolean;
  interviewBooked: boolean;
  agentConnections: Array<AgentBase>;
  isCandidateSelected?: boolean;
  previewUrl: string;
  ranking: string;
};

export type CandidateRecommendation = {
  candidate: {
    name: string;
    recommendedSelf: boolean;
    jobId: number;
    emailless: boolean;
    internal: boolean;
  };
  sortBy?: string;
};

export type AgentBase = {
  id: number;
  name: string;
};

export enum CandidateGroupName {
  recommended,
  applied,
  screened,
  presented,
  hired,
  rejected,
  declined,
  invalid_email,
}

export enum CandidateEventSubSteps {
  Approved = 'approved',
  Reminded = 'reminded',
  WrongRole = 'wrong_role',
  HappyCurrent = 'happy_current',
  WrongLocation = 'wrong_location',
  SimilarJobs = 'similar_jobs',
  Rejected = 'rejected',
}

export interface IGroupCandidatesRequest {
  id: number;
  jobId?: number;
  sortBy?: string;
}

export interface IGroupCandidatesBatchRequest {
  ids: Array<{ id: number }>;
  jobId?: number;
  sortBy?: string;
}

export const DEFAULT_TAB_SELECTED = 1;

export interface IHistory {
  recipientIds?: number[];
  selectedTab?: string;
  templateName?: string;
  candidatesIds?: object[];
  candidateId?: number;
  sortByOptionString?: string;
  functionHandler?: string;
  jobId?: number;
}

export interface IUpdateExternalURL {
  candidateId: number;
  externalUrl: string;
  jobId: number;
}

export interface IDocumentUpload {
  id: number;
  document?: FileList;
  jobId?: number;
}

export interface IStepStatusUpdate {
  candidateId: number;
  status: string;
  jobId?: number;
}

export interface IRankingUpdate {
  candidateId: number;
  ranking: string;
  jobId?: number;
}

export interface IDropdownState {
  dropdown?: boolean;
  doc?: boolean;
  url?: boolean;
  step?: boolean;
  ranking?: boolean;
  number: number | undefined;
}

export interface ICandidate {
  id?: number;
  name?: string;
  email?: string;
  externalUrl?: string;
  document?: string;
  function?: string;
  experience?: string;
}

export enum CandidateStatus {
  Pending = 'pending',
  PlannedForInterview = 'planned_for_interview',
  InterviewRequested = 'interview_requested',
  InterviewConfirmed = 'Interview_confirmed',
  Passed = 'passed',
  OnHold = 'on_hold',
  Failed = 'failed',
}

export enum CandidateRanking {
  Unrated = 'Unrated',
  Rating_1 = 'Rating1',
  Rating_2 = 'Rating2',
  Rating_3 = 'Rating3',
}
export const candidateRankingList = [
  { label: 'Unrated', color: '#4472C4', option: CandidateRanking.Unrated },
  { label: 'Rating 3', color: '#FF0000', option: CandidateRanking.Rating_3 },
  { label: 'Rating 2', color: '#FFC20A', option: CandidateRanking.Rating_2 },
  { label: 'Rating 1', color: '#70AD47', option: CandidateRanking.Rating_1 },
];

export const candidateStatusList = [
  { label: 'Pending', color: '#4472C4', option: CandidateStatus.Pending },
  {
    label: 'Planned for interview',
    color: '#70AD47',
    option: CandidateStatus.PlannedForInterview,
  },
  {
    label: 'Interview requested',
    color: '#70AD47',
    option: CandidateStatus.InterviewRequested,
  },
  {
    label: 'Interview confirmed',
    color: '#70AD47',
    option: CandidateStatus.InterviewConfirmed,
  },
  { label: 'Passed', color: '#70AD47', option: CandidateStatus.Passed },
  { label: 'On hold', color: '#FFC20A', option: CandidateStatus.OnHold },
  { label: 'Failed', color: '#FF0000', option: CandidateStatus.Failed },
];

export enum ExperienceLevel {
  YOUNG_PROFESSIONAL = 'Young Professional',
  PROFESSIONAL = 'Professional',
  SENIOR_PROFESSIONAL = 'Senior Professional',
}

export const experienceLevelList = [
  { label: 'Young Professional', option: ExperienceLevel.YOUNG_PROFESSIONAL },
  { label: 'Professional', option: ExperienceLevel.PROFESSIONAL },
  { label: 'Senior Professional', option: ExperienceLevel.SENIOR_PROFESSIONAL },
];

export interface CandidateFilterEntry {
  experience?: string[];
  query?: string;
  function?: string[];
}
