import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiClient } from 'utils/api';
import { actions } from './slice';
import { Action, AuthUser } from 'models';

export function* authenticateAdminHandler({ payload }: Action) {
  const requestURL = `/Account/authenticate`;

  try {
    yield call(ApiClient.post, requestURL, payload);
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* fetchAuthenticatedUserHandler({ payload }: Action) {
  const requestURL = `/Account/authenticate`;

  try {
    const authUser: AuthUser = yield call(ApiClient.get, requestURL);
    localStorage.setItem(
      'AUTH_USER',
      JSON.stringify({ ...authUser, timestamp: new Date().getTime() }),
    );
    yield put(actions.setAuthentiactedUser(authUser));
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* logoutUserHandler({ payload }: Action) {
  const requestURL = `/Account/logout`;

  try {
    yield call(ApiClient.post, requestURL);
    localStorage.removeItem('AUTH_USER');
    localStorage.removeItem('AUTH_TOKEN');
    yield put(actions.resetAuthState());
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* authenticationSaga() {
  yield takeLatest(actions.sendLoginLink.type, authenticateAdminHandler);
  yield takeLatest(
    actions.fetchAuthenticatedUser.type,
    fetchAuthenticatedUserHandler,
  );
  yield takeLatest(actions.logoutUser.type, logoutUserHandler);
}
