export const GlobalNotifications = new Map();

GlobalNotifications.set('InternalServerError', {
  severityType: 'error',
  message: 'Internal server error',
});
GlobalNotifications.set('/adminuser:post', {
  severityType: 'success',
  message: 'User has been successfully created',
});
GlobalNotifications.set('/adminuser:post:ITEM_ALREADY_EXIST', {
  severityType: 'error',
  message: 'Email has already been taken',
});
GlobalNotifications.set('/adminuser:put', {
  severityType: 'success',
  message: 'User has been successfully updated',
});
GlobalNotifications.set('/faq:post', {
  severityType: 'success',
  message: 'FAQ has been successfully created',
});
GlobalNotifications.set('/faq:put', {
  severityType: 'success',
  message: 'FAQ has been successfully updated',
});
GlobalNotifications.set(`/faq:delete`, {
  severityType: 'success',
  message: 'FAQ has been successfully deleted',
});
GlobalNotifications.set('/Gdpr/policy:post', {
  severityType: 'success',
  message: 'New Policy now in effect',
});
GlobalNotifications.set('/Account/authenticate:post', {
  severityType: 'info',
  message: 'Check your email for a login link',
});
GlobalNotifications.set('/Lead/markAsHandled:put', {
  severityType: 'success',
  message: 'Successfully handled',
});
GlobalNotifications.set('/messaging/email/multipleRecipients:post:undefined', {
  severityType: 'success',
  message: 'Email successfully sent to recipient(s)',
});
GlobalNotifications.set('/agentmeeting:post', {
  severityType: 'success',
  message: 'Meeting added',
});
GlobalNotifications.set('/agentmeeting:put', {
  severityType: 'success',
  message: 'Meeting saved',
});
GlobalNotifications.set('/agentmeeting:delete', {
  severityType: 'success',
  message: 'Meeting deleted',
});
GlobalNotifications.set('/agentcomment:post:ITEM_CAN_NOT_BE_EMPTY', {
  severityType: 'error',
  message: "Didn't add empty comment",
});
GlobalNotifications.set('/agentcomment:post', {
  severityType: 'success',
  message: 'Comment added',
});
GlobalNotifications.set('/agentcomment:delete', {
  severityType: 'success',
  message: 'Comment deleted',
});
GlobalNotifications.set('/lead/admin:post', {
  severityType: 'success',
  message: 'Lead created',
});
GlobalNotifications.set('/AgentInvitation/batch:post', {
  severityType: 'success',
  message: 'Batch invite sent',
});
GlobalNotifications.set('/AgentInvitation/batch/send:post', {
  severityType: 'success',
  message: 'Invitation reminder(s) sent',
});
GlobalNotifications.set('/agent:delete', {
  severityType: 'success',
  message: 'Agent deleted',
});
GlobalNotifications.set('/agent:patch', {
  severityType: 'success',
  message: 'Agent updated',
});
GlobalNotifications.set('/AgentTag:delete', {
  severityType: 'success',
  message: 'Tag deleted',
});
GlobalNotifications.set('/AgentInvitation:post', {
  severityType: 'success',
  message: 'Invitation created',
});
GlobalNotifications.set('/AgentInvitation:post:ITEM_ALREADY_EXIST', {
  severityType: 'error',
  message: 'Agent already exists',
});
GlobalNotifications.set('/AgentInvitation:put', {
  severityType: 'success',
  message: 'Invitation updated',
});
GlobalNotifications.set('/AgentInvitation:delete', {
  severityType: 'info',
  message: 'Rescinded invitation for',
});
GlobalNotifications.set('/Job:post', {
  severityType: 'success',
  message: 'Job created',
});
GlobalNotifications.set('/Job:put', {
  severityType: 'success',
  message: 'Job updated',
});
GlobalNotifications.set('/Job:delete', {
  severityType: 'success',
  message: 'Job deleted',
});
GlobalNotifications.set('/Joboffline:put', {
  severityType: 'success',
  message: 'Taken offline',
});
GlobalNotifications.set('/Jobclose:put', {
  severityType: 'success',
  message: 'Closed for recommendations',
});
GlobalNotifications.set('/Jobarchive:put', {
  severityType: 'success',
  message: 'Archived',
});
GlobalNotifications.set('/Jobarchive:put:ONE_OR_MORE_ITEMS_NOT_VALID', {
  severityType: 'error',
  message: 'Still has unhandled candidates',
});
GlobalNotifications.set('/Candidateapprove:put', {
  severityType: 'success',
  message: 'Candidate approved',
});
GlobalNotifications.set('/Candidatereject:put', {
  severityType: 'success',
  message: 'Candidate rejected',
});
GlobalNotifications.set('/Candidateundo:put', {
  severityType: 'success',
  message: 'Candidate action reverted',
});
GlobalNotifications.set('/Candidate/batch/approve:put', {
  severityType: 'success',
  message: 'Candidates approved',
});
GlobalNotifications.set('/Candidate/batch/reject:put', {
  severityType: 'success',
  message: 'Candidates rejected',
});
GlobalNotifications.set('/Candidate/export:put', {
  severityType: 'success',
  message: 'Candidates exported',
});
GlobalNotifications.set('/Job/recommend/internal:post', {
  severityType: 'success',
  message: 'Candidate created',
});
GlobalNotifications.set('/event/status:put:ARGUMENT_IS_NOT_VALID', {
  severityType: 'error',
  message: "Can't open event unless all information is filled in",
});
GlobalNotifications.set('/JobPack:post', {
  severityType: 'success',
  message: 'Job pack created',
});
GlobalNotifications.set('/event:post', {
  severityType: 'success',
  message: 'Event created',
});
GlobalNotifications.set('/event:put', {
  severityType: 'success',
  message: 'Event saved',
});
GlobalNotifications.set('/event:delete', {
  severityType: 'success',
  message: 'Event deleted',
});
GlobalNotifications.set('/event/status:put:visible', {
  severityType: 'success',
  message: 'Event visible to agents',
});
GlobalNotifications.set('/event/status:put:open', {
  severityType: 'success',
  message: 'Event opened',
});
GlobalNotifications.set('/event/status:put:closed', {
  severityType: 'success',
  message: 'Event closed',
});
GlobalNotifications.set('/event/status:put:archived', {
  severityType: 'success',
  message: 'Event archived',
});
GlobalNotifications.set('/JobPack:put', {
  severityType: 'success',
  message: 'Job pack updated',
});
GlobalNotifications.set('/EmailTemplate:post', {
  severityType: 'success',
  message: 'Email template created',
});
GlobalNotifications.set('/EmailTemplate:put', {
  severityType: 'success',
  message: 'Email template updated',
});
GlobalNotifications.set('/EmailTemplate:delete', {
  severityType: 'success',
  message: 'Email template deleted',
});
GlobalNotifications.set('/JobTag:delete', {
  severityType: 'success',
  message: 'Tag deleted',
});
GlobalNotifications.set('/Candidate/externalUrl:put', {
  severityType: 'success',
  message: 'External URL updated',
});
GlobalNotifications.set('/Candidateupload:put', {
  severityType: 'success',
  message: 'Document updated',
});
GlobalNotifications.set('/Candidate/stepStatus:put', {
  severityType: 'success',
  message: 'Step status updated',
});
GlobalNotifications.set('/Candidate/ranking:put', {
  severityType: 'success',
  message: 'Rating updated',
});
GlobalNotifications.set('/TalentPools:delete', {
  severityType: 'success',
  message: 'Candidate deleted',
});
GlobalNotifications.set('/MarketJob:post', {
  severityType: 'success',
  message: 'Talent pool email successfully scheduled',
});
GlobalNotifications.set('/MarketJob:put', {
  severityType: 'success',
  message: 'Talent pool email edited',
});
GlobalNotifications.set('/MarketJob:delete', {
  severityType: 'success',
  message: 'Talent pool email successfully deleted',
});
