export interface IEvent {
  id?: number;
  city?: string;
  closedAt?: string;
  description?: string;
  emailImageBase64?: string;
  emailImageUrl?: string;
  guestLimit?: number;
  imageBase64?: string;
  imageUrl?: string;
  insertedAt?: string;
  locale?: string;
  openedAt?: string;
  speaker?: string;
  speakerDescription?: string;
  startDate?: string;
  startTime?: string;
  status?: EventStatus;
  title?: string;
  updatedAt?: string;
  agentTagsIncluded?: string[];
  agentTagsExcluded?: string[];
  eventHour?: string;
  eventMinutes?: string;
  previewKey?: string;
  agentEventStatus?: string;
}

export type EventsState = {
  events: Array<IEvent>;
  eventAttendees: Array<IEventAttendeeCard>;
  allInterestedAttendeesSelected?: boolean;
  allAttengingAttendeesSelected?: boolean;
  isEventDeleted?: boolean;
  isEventCreated?: boolean;
  previewEvent?: IEvent;
};

export interface IUpdateEventStatus {
  id: number;
  status: EventStatus;
}

export type UpdateEventStatusPayload = Pick<
  IUpdateEventStatus,
  'id' | 'status'
>;

export type EventsListProps = {
  events: Array<IEvent>;
  handleStatus: Function;
  deleteEvent: Function;
};

export enum EventStatus {
  DRAFT = 'draft',
  DISPLAYED = 'visible',
  OPEN = 'open',
  CLOSE = 'closed',
  ARCHIVED = 'archived',
}

export const ConfirmationQuestions = [
  {
    status: EventStatus.DRAFT,
    question: 'This will display the date for agents. Are you sure?',
  },
  {
    status: EventStatus.DISPLAYED,
    question: 'This will email invitations to agents. Are you sure?',
  },
  {
    status: EventStatus.OPEN,
    question: 'This will hide the event for agents. Are you sure?',
  },
  {
    status: EventStatus.CLOSE,
    question: 'This will archive the event. Are you sure?',
  },
];

export const EventButton = [
  {
    status: EventStatus.DRAFT,
    button: 'Display',
  },
  {
    status: EventStatus.DISPLAYED,
    button: 'Open',
  },
  {
    status: EventStatus.OPEN,
    button: 'Close',
  },
  {
    status: EventStatus.CLOSE,
    button: 'Archive',
  },
];

export const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const minutes = ['00', '30'];

export interface EventParams {
  eventId: string;
}

export interface IEventAttendee {
  id: number;
  name?: string;
  email?: string;
  agentId?: number;
  isAttendeeSelected?: boolean;
}

export interface IEventAttendeeCard {
  status: string;
  attendees: Array<IEventAttendee>;
}

export enum EventAttendeesStatus {
  INTERESTED = 'interested',
  ATTENDING = 'attending',
  ATTENDED = 'attended',
  CANCELED = 'canceled',
}

export const EventAttendeesButton = [
  {
    status: EventAttendeesStatus.INTERESTED,
    button: EventAttendeesStatus.ATTENDING,
  },
  {
    status: EventAttendeesStatus.ATTENDING,
    button: EventAttendeesStatus.ATTENDED,
  },
];

export interface IEventAttendeeSelectUpdate {
  status: string;
  id?: number;
}

export interface IEventAttendeeUpdate {
  status: string;
  attendees: Array<IEventAttendee>;
  eventId: string;
}

export interface EventPreviewParams {
  previewKey: string;
}

export const ConfirmationAttendeesQuestions = [
  {
    status: EventAttendeesStatus.ATTENDING,
    question: 'This will send an e-mail confirmation. Are you sure?',
  },
  {
    status: EventAttendeesStatus.ATTENDED,
    question:
      'This will mark all selected agents as having attended. Are you sure?',
  },
  {
    status: EventAttendeesStatus.CANCELED,
    question:
      'This will mark all selected agents as having canceled. Are you sure?',
  },
];
