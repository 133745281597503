import { createSelector } from 'reselect';
import { RootState } from 'types';
import { initialState } from './slice';
import { IJobWithCount } from 'models';

const selectDomain = (s: RootState) => s.jobsContainer || initialState;

// eslint-disable-next-line
const getJobId = ({}, jobId: number) => jobId;

export const selectLoading = createSelector(
  [selectDomain],
  ({ loading }) => loading,
);
export const selectError = createSelector([selectDomain], ({ error }) => error);

export const selectJobsWithCount = createSelector(
  [selectDomain],
  ({ jobsWithCount }) => jobsWithCount,
);

export const selectSortedJobsWithCount = createSelector(
  [selectDomain],
  ({ jobsWithCount }) =>
    jobsWithCount.slice().sort(sortJobWithCountsArrayByTitle),
);

export const selectedJobs = createSelector(
  [selectDomain],
  ({ jobsWithCount }) =>
    jobsWithCount.filter(job => job.isJobSelected === true).map(job => job.id),
);

export const selectedEditJobs = createSelector(
  [selectDomain],
  ({ jobPackEdit }) =>
    jobPackEdit &&
    jobPackEdit.allJobs &&
    jobPackEdit.allJobs
      .filter(job => job.isJobSelected === true)
      .map(job => job.id),
);

export const selectJobById = createSelector([selectDomain], ({ job }) => job);

export const selectUpdatedJob = createSelector(
  [selectDomain],
  ({ updatedJob }) => updatedJob,
);

export const selectFailedTransitionJob = createSelector(
  [selectDomain],
  ({ failedTransitionJob }) => failedTransitionJob,
);

export const selectJobPacks = createSelector(
  [selectDomain],
  ({ jobPacks }) => jobPacks,
);

export const selectedJobId = createSelector(
  [selectDomain],
  ({ selectedJobId }) => selectedJobId,
);

export const selectJobPackById = createSelector(
  [selectDomain],
  ({ jobPackEdit }) => jobPackEdit && jobPackEdit.data,
);

export const selectJobPackJobs = createSelector(
  [selectDomain],
  ({ jobPackEdit }) => jobPackEdit && jobPackEdit.allJobs,
);

export const selectJobTags = createSelector(
  [selectDomain],
  ({ jobTags }) => jobTags,
);

export const selectNewJobTag = createSelector(
  [selectDomain],
  ({ newJobTag }) => newJobTag,
);

export const sortJobWithCountsArrayByTitle = (
  item1: IJobWithCount,
  item2: IJobWithCount,
) => {
  if (item1.title > item2.title) return 1;
  if (item1.title < item2.title) return -1;
  return 0;
};

export const selectEmailRecipients = createSelector(
  [selectDomain],
  ({ emailRecipients }) => emailRecipients,
);

export const selectCandidateSendout = createSelector(
  [selectDomain],
  ({ candidateSendout }) => candidateSendout,
);

export const selectMarketJob = createSelector(
  [selectDomain],
  ({ marketJob }) => marketJob,
);

export const selectRedirectJobs = createSelector(
  [selectDomain],
  ({ redirectJobs }) => redirectJobs,
);
