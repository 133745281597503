import { User } from 'models';
import { IAgent } from './recruit';

export interface IAgentTag {
  id?: number;
  insertedAt?: string;
  updatedAt?: string;
  label: string;
  color?: string;
  numberOfUses?: number;
  agentTaggingId?: number;
}

export interface IAgentNewTag {
  tagLabel: IAgentTag;
  agentId: number;
}

export interface IAgentTagging {
  id?: number;
  insertedAt?: string;
  updatedAt?: string;
  agentId: number;
  tagId: number;
  tag?: IAgentTag;
}

export interface IAgentDeleteTag {
  id: number;
}

export interface IAgentUpdateTags {
  tagsToBeAdded: IAgentTagging[];
  tagsToBeDeleted: IAgentTagging[];
}

export interface IAgentAdminMeeting {
  id?: number | null;
  adminId: number | null;
  agentId?: number;
  status: string;
  comment?: string;
  inPerson: boolean;
  insertedAt?: string;
  updatedAt?: string;
  startsAt?: string;
}

export interface IAgentAdminComment {
  id?: number;
  agentId: number;
  body: string;
  insertedAt: string;
  updatedAt: string;
  authorId: number;
}

export interface IActiveAgent extends IAgent {
  agentTags: Array<IAgentTag>;
  agentAdminMeetings: Array<IAgentAdminMeeting>;
  agentAdminComments: Array<IAgentAdminComment>;
  isAgentSelected?: boolean;
  isEmailNameUpdated?: boolean;
  meetingStartDate?: string;
}

export interface IAgentInvitation {
  id: number;
  insertedAt?: string;
  updatedAt?: string;
  name: string;
  email: string;
  token?: string;
  bouncedAt?: string;
  opened?: boolean;
  clicked?: boolean;
  sentAt?: string;
  comment: string;
  adminId: number;
  agentMeeting: IAgentAdminMeeting;
  company: string;
  location: string;
  tags: string[];
  admin?: User;
  meetingAdmin?: User;
  send?: number;
  isAgentSelected?: boolean;
  jobTitle?: string;
  function?: string;
  industry?: string;
  phoneNumber?: string;
  emailName?: string;
}

export interface IAgentInvitationSend extends IAgentInvitation {
  //update when BE is done
}

export interface IAgentBatchInvitationSend {
  ids: Array<Object>;
}

export interface AgentEditParams {
  agentId: string;
}

export interface ICustomField {
  key: string;
  value: string;
  hasErrors?: boolean;
}

export interface IEmail {
  sender?: number;
  subject: string;
  body: string;
  footer?: string | null;
  customFields?: Array<ICustomField>;
  recipients?: Array<IActiveAgent>;
  agentMeeting?: IAgentAdminMeeting;
  candidateIds?: number[];
  templateName?: string;
}

export interface IParty {
  account: string;
  name: string;
  id?: string | number;
}

export interface IEmailRequest {
  body: string;
  from: IParty;
  plainText: string;
  category: string | null;
  tos: Array<IParty>;
  subjects: Array<string>;
  customFields: Array<Array<ICustomField>>;
}

export interface IEmailTemplate {
  name: string;
  subject: string;
  body: string;
  type: TemplateType;
  id?: number;
  insertedAt?: string;
  updatedAt?: string;
}

export interface IEmailTemplateRequest {
  name: string;
  subject: string;
  body: string;
  candidateIds: number[];
  placeholders?: string[];
  id?: number;
  insertedAt?: string;
  updatedAt?: string;
}

export interface IAgentEventStatistics {
  interested: number;
  attending: number;
  attended: number;
  canceled: number;
}

export interface ILeadStat {
  month: string;
  count: number;
}

export interface IAgentLeadsStatistics {
  lifetime: number;
  monthlyStats: Array<ILeadStat>;
}

export interface IRecStepStat {
  step: string;
  count: number;
}

export interface IRecStat {
  month: string;
  count: number;
}

export interface IAgentCandidatesStatistics {
  percentageOfJobs: number;
  recomendations: Array<IRecStepStat>;
  monthlyStats: Array<IRecStat>;
}

//for redux
export type AgentsState = {
  agents: Array<IActiveAgent>;
  allAgentsSelected?: boolean;
  agentTags?: Array<IAgentTag>;
  addedBatchTags?: Array<IAgentTagging>;
  addedTagsFromList?: boolean;
  users?: Array<User>;
  agentInvitation: IAgentInvitation | null;
  agentInvitations: Array<IAgentInvitation>;
  allAgentInvitationsSelected?: boolean;
  activeAgent: IActiveAgent | null;
  eventStatistics?: IAgentEventStatistics;
  leadsStatistics?: IAgentLeadsStatistics;
  candidatesStatistics?: IAgentCandidatesStatistics;
  newTag?: IAgentTag;
  filterOptions?: FilterOptions;
  deletedAgentInvitation?: IAgentInvitation;
  agentsLocations?: Array<string>;
  agentsCompanies?: Array<string>;
  sentAgentReminder?: IAgentInvitation;
  filteredAgents?: Array<IActiveAgent>;
  activeView?: string;
  selectedTagAgents?: Array<number>;
};

export type AgentsListProps = {
  agents: Array<IActiveAgent>;
  selectedAgent: Function;
  selectAllAgents: Function;
};

export type AgentInvitationsListProps = {
  agentInvitations: Array<IAgentInvitation>;
  deleteAgentInvitation: Function;
  selectedAgentInvitation: Function;
  selectAllAgentInvitations: Function;
  sendBatchInvitations: Function;
  sendAgentInvitationReminder: Function;
};

export type ExportAgentInvitationsProps = {
  exportAgentInvites: Function;
};

export enum ViewType {
  SIGN_UP_DATE = 'Sign up date',
  ADMIN = 'Admin',
  COMPANY = 'Company',
  FUNCTION = 'Function',
  INDUSTRY = 'Industry',
  LOCATION = 'Location',
}

export type ViewBoxProps = {
  exportAgents: Function;
  filterAgents: Function;
  createNewTag: Function;
  addBatchTags: Function;
  setAddedTagsFromList: Function;
  searchAgents?: Function;
  setViewType: Function;
  resetFilterOptions: Function;
  fetchAgents: Function;
  resetSelectedTagAgents: Function;
};

export type UpdateEmailName = { id: number; emailName: string };

export type MissingEmailsProps = {
  agents: Array<IActiveAgent>;
  updateAgentName: Function;
  updateAllAgentNames: Function;
};

export type CustomFieldProps = {
  handleCustomFields: Function;
};

export type ActiveAgentPayload = Pick<
  IActiveAgent,
  | 'name'
  | 'emailName'
  | 'email'
  | 'phoneNumber'
  | 'location'
  | 'adminId'
  | 'jobTitle'
  | 'function'
  | 'industry'
  | 'company'
>;

export type AgentEventStatisticsPaylaod = Pick<
  IAgentEventStatistics,
  'attended' | 'interested' | 'attending' | 'canceled'
>;

export type AgentLeadsStatisticsPaylaod = Pick<
  IAgentLeadsStatistics,
  'lifetime' | 'monthlyStats'
>;

export type AgentCandidatesStatisticsPayload = Pick<
  IAgentCandidatesStatistics,
  'percentageOfJobs' | 'recomendations' | 'monthlyStats'
>;

export const meetingStatuses = [
  { id: -1, label: 'All' },
  { id: 0, label: 'No meeting' },
  { id: 1, label: 'Email sent' },
  { id: 2, label: 'In contact' },
  { id: 3, label: 'Follow up' },
  { id: 4, label: 'Booked' },
  { id: 5, label: 'Completed' },
  { id: 6, label: 'Declined' },
  { id: 7, label: 'Reminder sent' },
  { id: 8, label: 'Suspended' },
];

export interface AgentInvitationFormParams {
  invitationId: string;
}

export interface FilterOptions {
  admin?: string[];
  location?: string[];
  company?: string[];
  function?: string[];
  industry?: string[];
  emailSettings?: string[];
  tagsExclusion?: boolean;
  tags?: string[];
  meetingStatus?: string;
  meetingsFrom?: string;
  meetingsTo?: string;
}

export enum MeetingQuickLinks {
  OLDER_5_MONTHS,
  OLDER_6_MONTHS,
  LAST_MONTH,
  THIS_WEEK,
  LAST_WEEK,
}

export enum FunctionEnum {
  ADMINISTRATION = 'Administration',
  B2B_SALES = 'B2B Sales',
  B2C_SALES = 'B2C Sales',
  COMMUNICATION_PR = 'Communication / PR',
  CONSULTING = 'Consulting',
  CUSTOMER_SERVICE_SUPPORT = 'Customer service / support',
  DESIGN = 'Design',
  ENGINEERING = 'Engineering',
  EVENT_MANAGEMENT = 'Event management',
  FINANCE_ACCOUNTING = 'Finance / Accounting',
  GENERAL_MANAGEMENT = 'General Management',
  HUMAN_RESOURCES = 'Human Resources',
  IT_PROGRAMMING = 'IT / Programming',
  LAW = 'Law',
  LOGISTICS = 'Logistics',
  MANAGEMENT_CONSULTING = 'Management consulting',
  MANUFACTURING = 'Manufacturing',
  MARKETING = 'Marketing',
  MEDIA = 'Media',
  OPERATIONS_MANAGEMENT = 'Operations management',
  OTHER = 'Other',
  PURCHASING = 'Purchasing',
  RESEARCH_DEVELOPMENT = 'Research & Development',
}

export enum Industry {
  ADVERTISING_PR_MARKET_RESEARCH = 'Advertising / PR / Market Research',
  AGRICULTURE_FORESTRY_HUNTING_FISHING = 'Agriculture, Forestry, Hunting & Fishing',
  AIRLINE_TRAVEL_TOURISM = 'Airline, Travel & Tourism',
  AUDITING_ACCOUNTING_TAXATION = 'Auditing, Accounting, Taxation',
  AUTOMOTIVE = 'Automotive',
  BANK_FINANCE_INSURANCE = 'Bank, Finance & Insurance',
  BIOTECHNOLOGY = 'Biotechnology',
  CHEMICAL_PETROLUEM = 'Chemical / Petroluem',
  CONSTRUCTION_DESIGN = 'Construction / Design',
  CULTURE_ENTERTAINMENT_LEISURE = 'Culture, Entertainment & Leisure',
  DEFENCE = 'Defence',
  E_COMMERCE = 'e-Commerce',
  EDUCATION = 'Education',
  ELECTRONICS = 'Electronics',
  EMBASSIES_INTERNATIONAL_ORGANIZATIONS = 'Embassies & International Organizations',
  EMPLOYERS_ORGANIZATIONS_INDUSTRY_TRADE_GROUPS = 'Employers Organizations / Industry Trade Groups',
  ENERGY_POWER_UTILITIES = 'Energy / Power / Utilities',
  FAST_MOVING_CONSUMER_GOODS = 'Fast Moving Consumer Goods',
  FOOD_PROCESSING_SALES = 'Food Processing & Sales',
  HAIR_BEAUTY_CARE = 'Hair & Beauty Care',
  HEALTHCARE_WELLBEING = 'Healthcare & wellbeing',
  HOSPITALITY = 'Hospitality',
  HUMAN_RESOURCES_RECRUITMENT = 'Human Resources & Recruitment',
  IT_SERVICES = 'IT Services',
  LEGAL_SERVICES = 'Legal Services',
  LOGISTICS = 'Logistics',
  MANAGEMENT_CONSULTING = 'Management Consulting',
  MANUFACTURING = 'Manufacturing',
  MEDIA_ENTERTAINEMENT = 'Media / Entertainement',
  METALS_MINING = 'Metals / Mining',
  OTHER = 'Other',
  PHARMACEUTICALS = 'Pharmaceuticals',
  PUBLIC_ADMINISTRATION = 'Public Administration',
  REAL_ESTATE = 'Real Estate',
  REPAIR_INSTALLATION = 'Repair / Installation',
  RETAIL = 'Retail',
  STAFFING_EMPLOYMENT_AGENCIES = 'Staffing & Employment Agencies',
  TELECOMMUNICATIONS = 'Telecommunications',
}

export interface IDownload {
  name: string;
  base64: string;
}

export type TemplatesState = {
  emailTemplates: IEmailTemplate[];
  template?: IEmailTemplate;
  modalOpen?: boolean;
};

export interface IListEntry {
  label: string;
  option: string | number;
}

export enum TemplateType {
  LINKED = 'linked',
  AD_HOC = 'ad_hoc',
}

export enum EmailSettings {
  NEW_JOBS = 'New jobs',
  CANDIDATE_UPDATES = 'Candidate updates',
  NEWSLETTER = 'Newsletter',
  UPCOMING_EVENTS = 'Upcoming events',
}

export const EmailSettingsList = [
  { label: 'New jobs', option: EmailSettings.NEW_JOBS },
  { label: 'Candidate updates', option: EmailSettings.CANDIDATE_UPDATES },
  { label: 'Newsletter', option: EmailSettings.NEWSLETTER },
  { label: 'Upcoming events', option: EmailSettings.UPCOMING_EVENTS },
];

export const candidatePlaceholders = [
  { key: 'Candidate first name', value: '{{ candidate.first_name }}' },
  { key: 'candidate full name', value: '{{ candidate.full_name }}' },
  { key: 'job title ', value: '{{ job_title }}' },
];

export const functionList = [
  { label: 'Administration', option: FunctionEnum.ADMINISTRATION },
  { label: 'B2B Sales', option: FunctionEnum.B2B_SALES },
  { label: 'B2C Sales', option: FunctionEnum.B2C_SALES },
  { label: 'Communication / PR', option: FunctionEnum.COMMUNICATION_PR },
  { label: 'Consulting', option: FunctionEnum.CONSULTING },
  {
    label: 'Customer service / support',
    option: FunctionEnum.CUSTOMER_SERVICE_SUPPORT,
  },
  { label: 'Design', option: FunctionEnum.DESIGN },
  { label: 'Engineering', option: FunctionEnum.ENGINEERING },
  { label: 'Event management', option: FunctionEnum.EVENT_MANAGEMENT },
  { label: 'Finance / Accounting', option: FunctionEnum.FINANCE_ACCOUNTING },
  { label: 'General Management', option: FunctionEnum.GENERAL_MANAGEMENT },
  { label: 'Human Resources', option: FunctionEnum.HUMAN_RESOURCES },
  { label: 'IT / Programming', option: FunctionEnum.IT_PROGRAMMING },
  { label: 'Law', option: FunctionEnum.LAW },
  { label: 'Logistics', option: FunctionEnum.LOGISTICS },
  {
    label: 'Management consulting',
    option: FunctionEnum.MANAGEMENT_CONSULTING,
  },
  { label: 'Manufacturing', option: FunctionEnum.MANUFACTURING },
  { label: 'Marketing', option: FunctionEnum.MARKETING },
  { label: 'Media', option: FunctionEnum.MEDIA },
  {
    label: 'Operations management',
    option: FunctionEnum.OPERATIONS_MANAGEMENT,
  },
  { label: 'Other', option: FunctionEnum.OTHER },
  { label: 'Purchasing', option: FunctionEnum.PURCHASING },
  {
    label: 'Research & Development',
    option: FunctionEnum.RESEARCH_DEVELOPMENT,
  },
];

export const industryList = [
  {
    label: 'Advertising / PR / Market Research',
    option: Industry.ADVERTISING_PR_MARKET_RESEARCH,
  },
  {
    label: 'Agriculture, Forestry, Hunting & Fishing',
    option: Industry.AGRICULTURE_FORESTRY_HUNTING_FISHING,
  },
  {
    label: 'Airline, Travel & Tourism',
    option: Industry.AIRLINE_TRAVEL_TOURISM,
  },
  {
    label: 'Auditing, Accounting, Taxation',
    option: Industry.AUDITING_ACCOUNTING_TAXATION,
  },
  { label: 'Automotive', option: Industry.AUTOMOTIVE },
  {
    label: 'Bank, Finance & Insurance',
    option: Industry.BANK_FINANCE_INSURANCE,
  },
  { label: 'Biotechnology', option: Industry.BIOTECHNOLOGY },
  { label: 'Chemical / Petroluem', option: Industry.CHEMICAL_PETROLUEM },
  { label: 'Construction / Design', option: Industry.CONSTRUCTION_DESIGN },
  {
    label: 'Culture, Entertainment & Leisure',
    option: Industry.CULTURE_ENTERTAINMENT_LEISURE,
  },
  { label: 'Defence', option: Industry.DEFENCE },
  { label: 'e-Commerce', option: Industry.E_COMMERCE },
  { label: 'Education', option: Industry.EDUCATION },
  { label: 'Electronics', option: Industry.ELECTRONICS },
  {
    label: 'Embassies & International Organizations',
    option: Industry.EMBASSIES_INTERNATIONAL_ORGANIZATIONS,
  },
  {
    label: 'Employers Organizations / Industry Trade Groups',
    option: Industry.EMPLOYERS_ORGANIZATIONS_INDUSTRY_TRADE_GROUPS,
  },
  {
    label: 'Energy / Power / Utilities',
    option: Industry.ENERGY_POWER_UTILITIES,
  },
  {
    label: 'Fast Moving Consumer Goods',
    option: Industry.FAST_MOVING_CONSUMER_GOODS,
  },
  {
    label: 'Food Processing & Sales',
    option: Industry.FOOD_PROCESSING_SALES,
  },
  { label: 'Hair & Beauty Care', option: Industry.HAIR_BEAUTY_CARE },
  { label: 'Healthcare & wellbeing', option: Industry.HEALTHCARE_WELLBEING },
  { label: 'Hospitality', option: Industry.HOSPITALITY },
  {
    label: 'Human Resources & Recruitment',
    option: Industry.HUMAN_RESOURCES_RECRUITMENT,
  },
  { label: 'IT Services', option: Industry.IT_SERVICES },
  { label: 'Legal Services', option: Industry.LEGAL_SERVICES },
  { label: 'Logistics', option: Industry.LOGISTICS },
  { label: 'Management Consulting', option: Industry.MANAGEMENT_CONSULTING },
  { label: 'Manufacturing', option: Industry.MANUFACTURING },
  { label: 'Metals / Mining', option: Industry.AUTOMOTIVE },
  { label: 'Other', option: Industry.OTHER },
  { label: 'Pharmaceuticals', option: Industry.PHARMACEUTICALS },
  { label: 'Public Administration', option: Industry.PUBLIC_ADMINISTRATION },
  { label: 'Real Estate', option: Industry.REAL_ESTATE },
  { label: 'Repair / Installation', option: Industry.REPAIR_INSTALLATION },
  { label: 'Retail', option: Industry.RETAIL },
  {
    label: 'Staffing & Employment Agencies',
    option: Industry.STAFFING_EMPLOYMENT_AGENCIES,
  },
  { label: 'Telecommunications', option: Industry.TELECOMMUNICATIONS },
];
