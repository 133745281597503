import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { Routes } from 'global/Routes';

import 'styles/main.scss';
import { StylesProvider } from '@material-ui/core/styles';

import { PrivateRoutesContainer, LoginContainer } from 'containers';

import { useInjectReducer, useInjectSaga } from 'utils/reduxInjectors';
import { sliceKey, reducer } from '../store/appSlice';

import { selectNotification } from '../store/appSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'components/Toast';

import {
  sliceKey as authSliceKey,
  reducer as authReducer,
  actions as authActions,
} from '../store/auth/slice';
import { authenticationSaga } from '../store/auth/saga';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { IUserLogin, ROLES, TOKEN_EXIPRATION_TIME_HOURS } from 'models';

import {
  sliceKey as agentsSliceKey,
  reducer as agentsReducer,
  actions as agentsActions,
} from '../store/agents/slice';
import { actions as jobsActions } from '../store/jobs/slice';
import { selectDeletedAgentInvitation } from 'store/agents/selectors';
import {
  selectUpdatedJob,
  selectFailedTransitionJob,
  selectCandidateSendout,
} from 'store/jobs/selectors';
import { differenceInHours } from 'date-fns';

function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  useInjectSaga({ key: sliceKey, saga: authenticationSaga });
  useInjectReducer({ key: agentsSliceKey, reducer: agentsReducer });

  const notification = useSelector(selectNotification);
  const _isAuthenticated = useSelector(selectIsAuthenticated);
  const deletedAgentInvitation = useSelector(selectDeletedAgentInvitation);
  const updatedJob = useSelector(selectUpdatedJob);
  const failedTransitionJob = useSelector(selectFailedTransitionJob);
  const candidateSendout = useSelector(selectCandidateSendout);
  const dispatch = useDispatch();

  if (_isAuthenticated && localStorage.getItem('AUTH_USER')) {
    const authUser = JSON.parse(localStorage.getItem('AUTH_USER') ?? '');
    const insertedAt = authUser ? authUser.timestamp : '';
    if (insertedAt) {
      const diff = differenceInHours(new Date(), new Date(insertedAt));
      if (diff >= TOKEN_EXIPRATION_TIME_HOURS) {
        localStorage.removeItem('AUTH_USER');
        localStorage.removeItem('AUTH_TOKEN');
      }
    }
  }

  const handleSendLoginLink = (email: string) => {
    const userLogin: IUserLogin = { email: email, role: ROLES.ADMIN };
    dispatch(authActions.sendLoginLink(userLogin));
  };

  const getAuthenticatedUser = (token: string) => {
    dispatch(authActions.fetchAuthenticatedUser(token));
  };

  const onToastClose = () => {
    dispatch(agentsActions.resetAgentFlagsForToasters());
    dispatch(jobsActions.resetJobsFlagsForToasters());
  };

  const handleLogout = () => {
    dispatch(authActions.logoutUser());
  };
  return (
    <BrowserRouter>
      <StylesProvider injectFirst>
        <Helmet titleTemplate="%s" defaultTitle="AgentumHQ admin">
          <meta name="description" content="Agentum" />
        </Helmet>
        {_isAuthenticated ? (
          <PrivateRoutesContainer handleLogout={handleLogout} />
        ) : (
          <Switch>
            <Route exact path={Routes.LOGIN}>
              <LoginContainer
                handleSendLoginLink={handleSendLoginLink}
                getAuthenticatedUser={getAuthenticatedUser}
              />
            </Route>
            <Route path="/*">
              <Redirect to={Routes.LOGIN} />
            </Route>
          </Switch>
        )}
        {/* NOTE: Don't forget to reset the state used in the dynamic toasters */}
        {notification && (
          <Toast
            onToastClose={onToastClose}
            shown={true}
            message={
              deletedAgentInvitation
                ? `${notification.message} ${deletedAgentInvitation.name} ${deletedAgentInvitation.email}`
                : updatedJob
                ? `${updatedJob.title} ${notification.message}`
                : failedTransitionJob &&
                  failedTransitionJob.transition === 'archive'
                ? `${failedTransitionJob.jobTitle} ${
                    notification.message
                  } <a href=${`/admin/jobs/${failedTransitionJob.jobId}`}/candidates> Fix. </a>`
                : candidateSendout
                ? 'Email will be sent when status of candidate(s) changes'
                : notification.message
            }
            severityType={notification.severityType}
          />
        )}
      </StylesProvider>
    </BrowserRouter>
  );
}

export default App;
