import { Routes } from 'global/Routes';

export interface ISubHeader {
  name: string;
  link: string;
  isButton?: boolean;
  rightSide?: boolean;
  isVisibleOnRoute?: string[];
}

export type subHeaderProps = {
  subHeaderItems: Array<ISubHeader>;
};

export const eventsRoutes = [
  { name: 'CURRENT', link: Routes.EVENTS },
  { name: 'ARCHIVE', link: Routes.ARCHIVE_EVENTS },
  {
    name: 'CREATE EVENT',
    link: Routes.EVENT_NEW,
    isButton: true,
    rightSide: true,
  },
];

export const recruitRoutes = [{ name: 'LEADS', link: Routes.RECRUIT }];

export const gdprRoutes = [
  { name: 'CANDIDATES', link: Routes.GDPR },
  { name: 'AGENT POLICY', link: Routes.AGENTPOLICY_GDPR },
  { name: 'CANDIDATE POLICY', link: Routes.CANDIDATEPOLICY_GDPR },
];

export const agentsRoutes = [
  { name: 'ACTIVE', link: Routes.AGENTS },
  { name: 'INVITES', link: Routes.AGENTS_INVITES },
  { name: 'EMAIL EDITOR', link: Routes.AGENTS_EMAIL_EDITOR, rightSide: true },
  { name: 'TAGS', link: Routes.AGENTS_TAGS },
  { name: 'INVITE AGENT', link: Routes.AGENTS_INVITES_NEW, isButton: true },
];

export const jobsRoutes = [
  { name: 'DRAFT', link: Routes.JOBS_DRAFT },
  { name: 'SCHEDULING', link: Routes.JOBS_SCHEDULING },
  { name: 'ONGOING', link: Routes.JOBS_ONGOING },
  { name: 'ARCHIVED', link: Routes.JOBS_ARCHIVE },
  { name: 'JOB TAGS', link: Routes.JOBS_TAGS, rightSide: true },
  {
    name: 'CREATE JOB',
    link: Routes.JOBS_NEW,
    isButton: true,
    isVisibleOnRoute: [
      Routes.JOBS_DRAFT,
      Routes.JOBS_ONGOING,
      Routes.JOBS_ARCHIVE,
      Routes.JOBS_TAGS,
    ],
  },
  {
    name: 'CREATE JOB PACK',
    link: Routes.JOBS_JOB_PACK_NEW,
    isButton: true,
    isVisibleOnRoute: [Routes.JOBS_SCHEDULING],
  },
];

export const candidatesRoutes = [
  { name: 'TALENT POOL', link: Routes.CANDIDATES },
];
