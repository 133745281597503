import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from 'types/ContainerState';
import { ErrorType } from 'types/ErrorType';
import {
  AgentsState,
  IActiveAgent,
  IAgentInvitation,
  IAgentTag,
  UsersState,
  IAgentInvitationSend,
  UpdateEmailName,
  IEmailRequest,
  ActiveAgentPayload,
  IAgentAdminMeeting,
  AgentEventStatisticsPaylaod,
  IAgentAdminComment,
  AgentLeadsStatisticsPaylaod,
  ILead,
  AgentCandidatesStatisticsPayload,
  FilterOptions,
  ViewType,
  IAgentBatchInvitationSend,
  IAgentTagging,
  IAgentUpdateTags,
  IAgentNewTag,
} from '../../models';

export const initialState: AgentsState & ContainerState = {
  agents: [],
  allAgentsSelected: false,
  agentTags: [],
  addedBatchTags: [],
  users: [],
  agentInvitation: null,
  agentInvitations: [],
  allAgentInvitationsSelected: false,
  activeAgent: null,
  filterOptions: undefined,
  sentAgentReminder: undefined,
  loading: true,
  error: null,
  filteredAgents: undefined,
  activeView: ViewType.SIGN_UP_DATE,
};

const agentsContainerSlice = createSlice({
  name: 'agentsContainer',
  initialState,
  reducers: {
    fetchAgents(state) {
      state.loading = true;
      state.error = null;
      state.filteredAgents = undefined;
      state.agents = [];
      state.agentsLocations = [];
      state.agentsCompanies = [];
    },
    setAgents(state, { payload }: PayloadAction<Array<IActiveAgent>>) {
      state.filteredAgents = undefined;
      state.loading = false;
      state.agents =
        payload.length > 0
          ? payload.map(agent => {
              if (
                state.selectedTagAgents &&
                state.selectedTagAgents.find(el => el === agent.id)
              ) {
                return { ...agent, isAgentSelected: true };
              } else {
                return { ...agent, isAgentSelected: false };
              }
            })
          : [];
      if (payload.length > 0) {
        const filteredLoc = Array.from(
          new Set(payload.map(a => a.location)),
        ).filter(l => l);
        //@ts-ignore
        state.agentsLocations = [...filteredLoc];
        const filteredCompanies = Array.from(
          new Set(payload.map(a => a.company)),
        ).filter(c => c);
        //@ts-ignore
        state.agentsCompanies = [...filteredCompanies];
      }
    },
    selectedAgent(state, { payload }: PayloadAction<number>) {
      const agent = state.agents.find(u => u.id === payload);
      if (agent) {
        agent.isAgentSelected = !agent.isAgentSelected;
        agent.isEmailNameUpdated = !agent.emailName;
        agent.emailName = !agent.emailName
          ? agent.name.split(' ')[0]
          : agent.emailName;

        if (state.allAgentsSelected && !agent.isAgentSelected) {
          state.allAgentsSelected = !state.allAgentsSelected;
        }
      }
      const filteredAgent =
        state.filteredAgents &&
        state.filteredAgents.find(u => u.id === payload);
      if (filteredAgent) {
        filteredAgent.isAgentSelected = !filteredAgent.isAgentSelected;
        filteredAgent.isEmailNameUpdated = !filteredAgent.emailName;
        filteredAgent.emailName = !filteredAgent.emailName
          ? filteredAgent.name.split(' ')[0]
          : filteredAgent.emailName;

        if (state.allAgentsSelected && !filteredAgent.isAgentSelected) {
          state.allAgentsSelected = !state.allAgentsSelected;
        }
      }
    },
    selectAllAgents(state) {
      state.allAgentsSelected = !state.allAgentsSelected;
      if (state.filteredAgents) {
        state.filteredAgents = state.filteredAgents.map(agent => ({
          ...agent,
          ...{
            isAgentSelected: state.allAgentsSelected,
            isEmailNameUpdated: !agent.emailName,
            emailName: !agent.emailName
              ? agent.name.split(' ')[0]
              : agent.emailName,
          },
        }));

        state.agents = state.agents.map(agent => {
          if (state.filteredAgents?.find(a => a.id === agent.id)) {
            return {
              ...agent,
              ...{
                isAgentSelected: state.allAgentsSelected,
                isEmailNameUpdated: !agent.emailName,
                emailName: !agent.emailName
                  ? agent.name.split(' ')[0]
                  : agent.emailName,
              },
            };
          }
          return agent;
        });
      } else {
        state.agents = state.agents.map(agent => ({
          ...agent,
          ...{
            isAgentSelected: state.allAgentsSelected,
            isEmailNameUpdated: !agent.emailName,
            emailName: !agent.emailName
              ? agent.name.split(' ')[0]
              : agent.emailName,
          },
        }));
      }
    },
    exportAgents(state, { payload }: PayloadAction<Array<IActiveAgent>>) {},
    updateAgentName(state, { payload }: PayloadAction<UpdateEmailName>) {
      const index = state.agents.findIndex(u => u.id === payload.id);
      state.agents[index].emailName = payload.emailName;
    },
    updateAllAgentNames(
      state,
      { payload }: PayloadAction<Array<IActiveAgent>>,
    ) {},
    setEmailNameUpdated(state, { payload }: PayloadAction<Array<number>>) {
      payload.forEach(id => {
        const index = state.agents.findIndex(u => u.id === id);
        //when false, means email name has been updated
        state.agents[index].isEmailNameUpdated = false;
      });
    },
    fetchAgentTags(state) {
      state.loading = true;
      state.error = null;
      state.addedBatchTags = undefined;
    },
    setAgentTags(state, { payload }: PayloadAction<Array<IAgentTag>>) {
      state.loading = false;
      state.agentTags = payload;
    },
    setAgentNewTags(state, { payload }: PayloadAction<Array<IAgentTag>>) {
      state.agentTags = payload;
    },
    fetchUsers(state) {
      state.loading = true;
      state.error = null;
      state.users = [];
    },
    setUsers(state, { payload }: PayloadAction<UsersState>) {
      const { users } = payload;
      state.loading = false;
      state.users = users;
    },
    createAgentInvitation(state, action: PayloadAction<IAgentInvitation>) {},
    setAgentInvitation(state, { payload }: PayloadAction<IAgentInvitation>) {
      state.loading = false;
      state.agentInvitation = payload;
    },
    sendAgentInvitation(state, action: PayloadAction<IAgentInvitationSend>) {},
    updateAgentInvitation(state, action: PayloadAction<IAgentInvitation>) {
      state.loading = true;
    },
    setUpdatedAgentInvitation(
      state,
      action: PayloadAction<IAgentInvitationSend>,
    ) {
      state.loading = false;
    },
    sendUpdatedAgentInvitation(
      state,
      action: PayloadAction<IAgentInvitationSend>,
    ) {
      state.loading = true;
    },
    sendBatchInvitations(
      state,
      action: PayloadAction<IAgentBatchInvitationSend>,
    ) {
      state.loading = true;
    },
    fetchAgentInvitations(state) {
      state.loading = true;
      state.error = null;
    },
    setAgentInvitations(state, { payload }: PayloadAction<IAgentInvitation[]>) {
      state.loading = false;
      state.agentInvitations =
        payload.length > 0
          ? payload.map(agentInvitation => ({
              ...agentInvitation,
              isAgentSelected: false,
            }))
          : [];
    },
    exportAgentInvitations(state) {
      state.loading = false;
    },
    selectedAgentInvitation(state, { payload }: PayloadAction<number>) {
      const agentInvitation = state.agentInvitations.find(
        u => u.id === payload,
      );
      if (agentInvitation) {
        agentInvitation.isAgentSelected = !agentInvitation.isAgentSelected;

        if (
          state.allAgentInvitationsSelected &&
          !agentInvitation.isAgentSelected
        ) {
          state.allAgentInvitationsSelected = !state.allAgentInvitationsSelected;
        }
      }
    },
    selectAllAgentInvitations(state) {
      state.allAgentInvitationsSelected = !state.allAgentInvitationsSelected;

      state.agentInvitations = state.agentInvitations.map(agentInvitation => ({
        ...agentInvitation,
        ...{
          isAgentSelected: state.allAgentInvitationsSelected,
        },
      }));
    },
    setSendAgentInvitations(
      state,
      { payload }: PayloadAction<IAgentInvitationSend>,
    ) {
      state.loading = false;
    },
    sendEmail(state, action: PayloadAction<IEmailRequest>) {
      state.loading = true;
    },
    setSendEmail(state) {
      state.loading = false;
    },
    deleteTag(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setDeleteTag(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.agentTags = state.agentTags?.filter(t => t.id !== payload);
    },
    fetchAgentAndStatisticsById(state, action: PayloadAction<number>) {
      state.loading = true;
      state.candidatesStatistics = undefined;
      state.eventStatistics = undefined;
      state.leadsStatistics = undefined;
      state.activeAgent = null;
    },
    setActiveAgent(state, { payload }: PayloadAction<IActiveAgent>) {
      state.loading = false;
      state.activeAgent = payload;
    },
    updateAgent(state, action: PayloadAction<ActiveAgentPayload>) {
      state.loading = true;
      state.newTag = undefined;
    },
    addAgentAdminMeeting(state, action: PayloadAction<IAgentAdminMeeting>) {
      state.loading = true;
    },
    setAgentAdminMeeting(
      state,
      { payload }: PayloadAction<IAgentAdminMeeting>,
    ) {
      state.loading = false;
      if (state.activeAgent) {
        state.activeAgent.agentAdminMeetings = [
          ...state.activeAgent.agentAdminMeetings,
          payload,
        ];
      }
    },
    deleteAgentAdminMeeting(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setDeleteAgentAdminMeeting(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      if (state.activeAgent) {
        state.activeAgent = {
          ...state.activeAgent,
          agentAdminMeetings: state.activeAgent.agentAdminMeetings.filter(
            m => m.id !== payload,
          ),
        };
      }
    },
    updateAgentAdminMeeting(state, action: PayloadAction<IAgentAdminMeeting>) {
      state.loading = true;
    },
    setUpdatedAgentAdminMeeting(
      state,
      { payload }: PayloadAction<IAgentAdminMeeting>,
    ) {
      state.loading = false;
      if (state.activeAgent) {
        const index = state.activeAgent.agentAdminMeetings.findIndex(
          m => m.id === payload.id,
        );
        state.activeAgent.agentAdminMeetings[index] = payload;
      }
    },
    fetchAgentEventStatistics(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setAgentEventStatistics(
      state,
      { payload }: PayloadAction<AgentEventStatisticsPaylaod>,
    ) {
      state.eventStatistics = payload;
    },
    addAgentAdminComment(state, action: PayloadAction<IAgentAdminComment>) {
      state.loading = true;
    },
    setAgentAdminComment(
      state,
      { payload }: PayloadAction<IAgentAdminComment>,
    ) {
      state.loading = false;
      if (state.activeAgent) {
        state.activeAgent.agentAdminComments = [
          ...state.activeAgent.agentAdminComments,
          payload,
        ];
      }
    },
    deleteAgentAdminComment(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setDeleteAgentAdminComment(state, { payload }: PayloadAction<number>) {
      state.loading = false;

      if (state.activeAgent) {
        state.activeAgent = {
          ...state.activeAgent,
          agentAdminComments: state.activeAgent.agentAdminComments.filter(
            c => c.id !== payload,
          ),
        };
      }
    },
    fetchAgentLeadsStatistics(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setAgentLeadsStatistics(
      state,
      { payload }: PayloadAction<AgentLeadsStatisticsPaylaod>,
    ) {
      state.leadsStatistics = payload;
    },
    fetchAgentCandidatesStatistics(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setAgentCandidatesStatistics(
      state,
      { payload }: PayloadAction<AgentCandidatesStatisticsPayload>,
    ) {
      state.candidatesStatistics = payload;
    },
    createAgentLead(state, action: PayloadAction<ILead>) {
      state.loading = true;
    },
    setAgentLead(state, { payload }: PayloadAction<ILead>) {
      state.loading = false;
    },
    inviteAgentsBatch(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setInviteAgentsBatch(state) {
      state.loading = false;
    },
    deleteAgent(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setDeleteAgent(state) {
      state.loading = false;
      state.activeAgent = null;
      state.filterOptions = undefined;
      state.allAgentsSelected = false;
    },
    createNewTag(state, action: PayloadAction<IAgentTag>) {},
    createNewAgentTag(state, action: PayloadAction<IAgentNewTag>) {
      state.loading = true;
    },
    setCreatedNewTag(state, { payload }: PayloadAction<IAgentTag>) {
      state.newTag = payload;
      state.agentTags = [...(state.agentTags || []), payload];
      state.agentTags = state.agentTags.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
      );
    },
    addBatchTags(state, { payload }: PayloadAction<IAgentTagging[]>) {
      state.newTag = undefined;
      state.addedBatchTags = payload;
      state.selectedTagAgents = payload.map(
        (tagging: IAgentTagging) => tagging.agentId,
      );
    },
    deleteBatchTags(state, { payload }: PayloadAction<IAgentTagging[]>) {
      state.loading = true;
    },
    updateBatchTags(state, { payload }: PayloadAction<IAgentUpdateTags>) {
      state.newTag = undefined;
    },
    addTagToAgent(state, { payload }: PayloadAction<IAgentTagging[]>) {
      state.loading = true;
      state.newTag = undefined;
    },
    setAddedTagsFromList(state) {
      state.addedTagsFromList = true;
    },

    deleteAgentInvitation(state, action: PayloadAction<IAgentInvitation>) {
      state.loading = true;
    },
    deleteAgentInvitationSuccess(
      state,
      { payload }: PayloadAction<IAgentInvitation>,
    ) {
      state.deletedAgentInvitation = payload;
      state.loading = false;
    },
    setViewType(state, { payload }: PayloadAction<string>) {
      state.activeView = payload;
    },
    fetchFilteredAgents(state, action: PayloadAction<FilterOptions>) {
      state.loading = true;
      state.filterOptions = action.payload;
      state.filteredAgents = undefined;
    },
    setFilteredAgents(state, { payload }: PayloadAction<Array<IActiveAgent>>) {
      state.loading = false;
      state.agents =
        payload.length > 0
          ? payload.map(agent => {
              if (
                state.selectedTagAgents &&
                state.selectedTagAgents.find(el => el === agent.id)
              ) {
                return { ...agent, isAgentSelected: true };
              } else {
                return { ...agent, isAgentSelected: false };
              }
            })
          : [];
    },
    resetFilterOptions(state) {
      state.filterOptions = undefined;
      state.allAgentsSelected = false;
      state.filteredAgents = undefined;
    },
    resetAgentInvitation(state) {
      state.agentInvitation = null;
    },
    sendAgentInvitationReminder(
      state,
      action: PayloadAction<IAgentInvitation>,
    ) {
      state.loading = true;
    },
    sendAgentInvitationReminderSuccess(
      state,
      { payload }: PayloadAction<IAgentInvitation>,
    ) {
      state.sentAgentReminder = payload;
      state.loading = false;
    },
    resetAgentFlagsForToasters(state) {
      state.deletedAgentInvitation = undefined;
      state.sentAgentReminder = undefined;
      state.addedBatchTags = [];
      state.addedTagsFromList = false;
    },
    createBatchMeetings(
      state,
      action: PayloadAction<Array<IAgentAdminMeeting>>,
    ) {},
    searchAgents(state, { payload }: PayloadAction<string>) {
      state.filteredAgents = payload
        ? state.agents.filter(
            agent =>
              agent.name.toLowerCase().includes(payload.toLowerCase()) ||
              agent.email.toLowerCase().includes(payload.toLowerCase()),
          )
        : undefined;
      state.allAgentsSelected = false;
    },
    exportAgent(state, { payload }: PayloadAction<number>) {},
    resetSelectedTagAgents(state) {
      state.selectedTagAgents = undefined;
      state.allAgentsSelected = false;
    },
    error(state, action: PayloadAction<ErrorType>) {
      state.error = action.payload;
      state.loading = false;
      state.newTag = undefined;
    },
  },
});

export const { actions, reducer, name: sliceKey } = agentsContainerSlice;
