type SeverityType = 'error' | 'info' | 'success' | 'warning';

export interface Notification {
  severityType: SeverityType;
  message: string;
}

export type AppState = { notification: Notification | null };

export interface AuthUser {
  id: number;
  name: string;
  email: string;
  role: number;
}

export const TOKEN_EXIPRATION_TIME_HOURS = 8;
