import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { actions } from '../store/appSlice';

type ToastProps = {
  severityType: SeverityType;
  message: string;
  shown: boolean;
  onToastClose: Function;
};

type SeverityType = 'error' | 'info' | 'success' | 'warning';

export const Toast: React.FC<ToastProps> = ({
  severityType,
  message,
  shown,
  onToastClose,
}) => {
  const [openToast, setOpenToast] = React.useState(shown);
  const dispatch = useDispatch();

  const handleClose = () => {
    onToastClose();
    setOpenToast(false);
    dispatch(actions.resetNotification());
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        open={openToast}
        autoHideDuration={severityType !== 'error' ? 5000 : null}
      >
        <Alert onClose={handleClose} variant="filled" severity={severityType}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(message, {
                allowedTags: false,
                allowedAttributes: false,
                allowedSchemesByTag: {
                  img: ['data', 'http', 'https'],
                },
              }),
            }}
          />
        </Alert>
      </Snackbar>
    </>
  );
};
