import * as React from 'react';
import { lazyLoad } from '../utils/loadable';
import { Loading } from '../components/Loading';

export const DashboardContainer = lazyLoad(
  () => import('./DashboardContainer'),
  module => module.DashboardContainer,
  {
    fallback: <Loading />,
  },
);

export const UsersContainer = lazyLoad(
  () => import('./UsersContainer'),
  module => module.UsersContainer,
  {
    fallback: <Loading />,
  },
);

export const FAQContainer = lazyLoad(
  () => import('./FAQContainer'),
  module => module.FAQContainer,
  {
    fallback: <Loading />,
  },
);

export const GDPRContainer = lazyLoad(
  () => import('./GDPRContainer'),
  module => module.GDPRContainer,
  {
    fallback: <Loading />,
  },
);

export const EventsContainer = lazyLoad(
  () => import('./EventsContainer'),
  module => module.EventsContainer,
  {
    fallback: <Loading />,
  },
);

export const RecruitContainer = lazyLoad(
  () => import('./RecruitContainer'),
  module => module.RecruitContainer,
  {
    fallback: <Loading />,
  },
);

export const LoginContainer = lazyLoad(
  () => import('./LoginContainer'),
  module => module.LoginContainer,
  {
    fallback: <Loading />,
  },
);

export const PrivateRoutesContainer = lazyLoad(
  () => import('./PrivateRoutesContainer'),
  module => module.PrivateRoutesContainer,
  {
    fallback: <Loading />,
  },
);

export const AgentsContainer = lazyLoad(
  () => import('./AgentsContainer'),
  module => module.AgentsContainer,
  {
    fallback: <Loading />,
  },
);

export const JobsContainer = lazyLoad(
  () => import('./JobsContainer'),
  module => module.JobsContainer,
  {
    fallback: <Loading />,
  },
);

export const PreviewEventContainer = lazyLoad(
  () => import('./PreviewEventContainer'),
  module => module.PreviewEventContainer,
  {
    fallback: <Loading />,
  },
);

export const EmailTemplatesContainer = lazyLoad(
  () => import('./EmailTemplatesContainer'),
  module => module.EmailTemplatesContainer,
  {
    fallback: <Loading />,
  },
);

export const LogsContainer = lazyLoad(
  () => import('./LogsContainer'),
  module => module.LogsContainer,
  {
    fallback: <Loading />,
  },
);

export const CandidatesContainer = lazyLoad(
  () => import('./CandidatesContainer'),
  module => module.CandidatesContainer,
  {
    fallback: <Loading />,
  },
);
