import { createSelector } from 'reselect';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (s: RootState) => s.auth || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  auth => auth.loading,
);

export const selectError = createSelector([selectDomain], auth => auth.error);

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  auth => auth.isAuthenticated,
);

export const selectAuthUser = createSelector(
  [selectDomain],
  auth => auth.authUser,
);
