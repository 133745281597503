import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from 'types/ContainerState';
import { ErrorType } from 'types/ErrorType';
import {
  INewJob,
  JobsState,
  IJobWithCount,
  IJob,
  IJobPack,
  UpdateJob,
  IJobPackEdit,
  CandidateRecommendation,
  IJobTag,
  CandidateGroupEntry,
  IEmailTemplateRequest,
  IMarketJob,
} from 'models';

export const initialState: JobsState & ContainerState = {
  loading: false,
  error: null,
  jobsWithCount: [],
  job: undefined,
  jobPacks: [],
  selectedJobId: undefined,
  jobPack: undefined,
  jobPackEdit: undefined,
  jobTags: undefined,
  newJobTag: undefined,
  emailRecipients: [],
  marketJob: undefined,
  candidateSendout: undefined,
  redirectJobs: false,
};

const jobsContainerSlice = createSlice({
  name: 'jobsContainer',
  initialState,
  reducers: {
    fetchJobTags(state) {
      state.loading = true;
      state.newJobTag = undefined;
    },
    setJobTags(state, { payload }: PayloadAction<Array<IJobTag>>) {
      state.loading = false;
      state.jobTags = payload;
    },
    deleteJobTag(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setDeleteJobTag(state, { payload }: PayloadAction<number>) {
      state.loading = false;
    },
    fetchJobs(state, action: PayloadAction<string>) {
      state.loading = true;
      state.job = undefined;
      state.marketJob = undefined;
      state.redirectJobs = false;
    },
    setJobsWithCount(state, { payload }: PayloadAction<Array<IJobWithCount>>) {
      state.loading = false;
      state.jobsWithCount =
        payload.length > 0
          ? payload.map(job => ({ ...job, isJobSelected: false }))
          : [];
      state.redirectJobs = false;
    },
    fetchJobById(state, { payload }: PayloadAction<number>) {
      state.loading = true;
    },
    setJobById(state, { payload }: PayloadAction<IJob>) {
      state.loading = false;
      state.job = payload;
    },
    createJob(state, { payload }: PayloadAction<INewJob>) {
      state.loading = true;
      state.newJobTag = undefined;
    },
    setCreatedJob(state) {
      state.loading = false;
      state.redirectJobs = true;
    },
    resetJobsWithCount(state) {
      state.jobsWithCount = [];
    },
    updateJob(state, { payload }: PayloadAction<INewJob>) {
      state.loading = false;
      state.newJobTag = undefined;
    },
    createPreviewLink(state, { payload }: PayloadAction<Object>) {
      state.loading = false;
    },
    deleteJob(state, { payload }: PayloadAction<number>) {
      state.loading = false;
    },
    updateJobSuccess(state, { payload }: PayloadAction<IJob>) {
      state.updatedJob = payload;
      state.loading = false;
    },
    selectJob(state, { payload }: PayloadAction<number>) {
      if (state.jobPackEdit) {
        const jobFromJobPack =
          state.jobPackEdit.allJobs &&
          state.jobPackEdit?.allJobs.find(j => j.id === payload);

        if (jobFromJobPack) {
          jobFromJobPack.isJobSelected = !jobFromJobPack.isJobSelected;
        }
      } else {
        const job = state.jobsWithCount.find(j => j.id === payload);
        if (job) {
          job.isJobSelected = !job.isJobSelected;

          if (state.selectedJobId) {
            state.selectedJobId = undefined;
          }
        }
      }
    },
    createJobPack(state, { payload }: PayloadAction<IJobPack>) {
      state.loading = true;
    },
    setCreatedJobPack(state) {
      state.loading = false;
      state.redirectJobs = true;
    },
    updateJobPack(state, { payload }: PayloadAction<IJobPack>) {
      state.loading = false;
    },
    setUpdatedJobPack(state) {
      state.loading = false;
      state.redirectJobs = true;
    },
    transitionJob(
      state,
      action: PayloadAction<{
        transition: string;
        jobId: number;
        jobTitle: string;
      }>,
    ) {
      state.loading = false;
    },
    transitionJobError(
      state,
      {
        payload,
      }: PayloadAction<{ transition: string; jobId: number; jobTitle: string }>,
    ) {
      state.failedTransitionJob = payload;
      state.loading = false;
    },
    fetchJobPacks(state) {
      state.loading = true;
      state.redirectJobs = false;
    },
    setJobPacks(state, { payload }: PayloadAction<Array<IJobPack>>) {
      state.loading = false;
      state.jobPacks = payload;
    },
    updateJobsStatus(state, { payload }: PayloadAction<Array<UpdateJob>>) {
      state.loading = false;
    },
    setSelectedJob(state, { payload }: PayloadAction<number>) {
      state.selectedJobId = payload;
    },
    jobStartSearch(state, { payload }: PayloadAction<number>) {
      state.loading = false;
    },
    fetchJobPackById(state, { payload }: PayloadAction<number>) {
      state.loading = true;
    },
    setJobPackById(state, { payload }: PayloadAction<IJobPackEdit>) {
      state.loading = false;
      state.jobPackEdit = payload;
    },
    resetJobPack(state) {
      state.jobPack = undefined;
      state.jobPackEdit = undefined;
    },
    recommendInternalCandidate(
      state,
      { payload }: PayloadAction<CandidateRecommendation>,
    ) {
      state.loading = false;
    },
    resetJobsFlagsForToasters(state) {
      state.failedTransitionJob = undefined;
      state.updatedJob = undefined;
      state.candidateSendout = undefined;
    },
    createNewJobTag(state, { payload }: PayloadAction<IJobTag>) {},
    setCreatedNewTag(state, { payload }: PayloadAction<IJobTag>) {
      state.newJobTag = payload;
      state.jobTags = [...(state.jobTags || []), payload];
      state.jobTags = state.jobTags.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
      );
    },
    setEmailRecipients(
      state,
      { payload }: PayloadAction<CandidateGroupEntry[]>,
    ) {
      state.emailRecipients = payload;
    },
    sendTemplateEmail(
      state,
      { payload }: PayloadAction<IEmailTemplateRequest>,
    ) {
      state.loading = true;
      state.candidateSendout = true;
    },

    fetchMarketJob(state, { payload }: PayloadAction<number>) {
      state.loading = true;
    },
    setMarketJob(state, { payload }: PayloadAction<IMarketJob>) {
      state.loading = false;
      state.marketJob = payload;
    },
    createMarketJob(state, { payload }: PayloadAction<IMarketJob>) {
      state.loading = true;
    },
    updateMarketJob(state, { payload }: PayloadAction<IMarketJob>) {
      state.loading = true;
    },
    deleteMarketJob(state, { payload }: PayloadAction<number>) {
      state.loading = true;
    },
    setDeletedMarketJob(state) {
      state.loading = false;
      state.redirectJobs = true;
    },
    redirectToJobs(state) {
      state.redirectJobs = true;
    },
    error(state, { payload }: PayloadAction<ErrorType>) {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = jobsContainerSlice;
