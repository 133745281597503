export class Routes {
  static DASHBOARD = '/admin';
  static USERS = '/admin/users';
  static NEW_USER = '/admin/users/new';
  static EDIT_USER = '/admin/users/:userId/edit';

  static FAQ = '/admin/faq';

  static GDPR = '/admin/gdpr';
  static AGENTPOLICY_GDPR = '/admin/gdpr/agent_policy';
  static CANDIDATEPOLICY_GDPR = '/admin/gdpr/candidate_policy';

  static EMAIL_TEMPLATES = '/admin/templates';

  static EVENTS = '/admin/events';
  static ARCHIVE_EVENTS = '/admin/events/archive';
  static EVENT_NEW = '/admin/events/new';
  static EVENT_EDIT = '/admin/events/:eventId/edit';
  static EVENT_ATTENDEES = '/admin/events/:eventId/attendees';
  static EVENT_ATTENDEES_PRINT = '/admin/events/:eventId/attendees/list';
  static EVENT_PREVIEW = '/admin/events/preview/:previewKey';

  static RECRUIT = '/admin/recruit';

  static AGENTS = '/admin/agents';
  static AGENTS_INVITES = '/admin/agents/invites';
  static AGENTS_INVITES_NEW = '/admin/agents/invites/new';
  static AGENTS_INVITES_EDIT = '/admin/agents/invites/:invitationId';
  static AGENTS_EMAIL_EDITOR = '/admin/agents/emaileditor';
  static AGENTS_TAGS = '/admin/agents/tags';
  static AGENT_EDIT = '/admin/agents/:agentId';

  static JOBS = '/admin/jobs';
  static JOBS_DRAFT = '/admin/jobs/draft';
  static JOBS_ONGOING = '/admin/jobs/ongoing';
  static JOBS_ARCHIVE = '/admin/jobs/archive';
  static JOBS_SCHEDULING = '/admin/jobs/scheduling';
  static JOBS_JOB_PACK_NEW = '/admin/jobs/scheduling/new';
  static JOBS_JOB_PACK_EDIT = '/admin/jobs/scheduling/:jobPackId/edit';
  static JOBS_TAGS = '/admin/jobs/tags';
  static JOBS_NEW = '/admin/jobs/new';
  static JOBS_EDIT = `/admin/jobs/:jobId/edit`;
  static JOBS_CANDIDATES = '/admin/jobs/:jobId/candidates';
  static JOBS_EMAIL_EDITOR = '/admin/jobs/emaileditor';
  static JOBS_MARKET_TO_TALENT_POOL = '/admin/jobs/market-talent-pool/new';
  static JOBS_MARKET_TO_TALENT_POOL_EDIT =
    '/admin/jobs/market-talent-pool/:marketJobId/edit';

  static LOGS = '/admin/logs';

  static LOGIN = '/admin/login';

  static CANDIDATES = '/admin/candidates';
}
