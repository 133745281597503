import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from 'types/ErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { AuthUser, IUserLogin } from 'models';

export interface AuthState {
  isAuthenticated: boolean;
  authUser?: AuthUser;
  loading: boolean;
  error: ErrorType | null;
}

export const initialState: AuthState = {
  isAuthenticated: localStorage.getItem('AUTH_TOKEN') ? true : false,
  loading: false,
  error: null,
};

const AuthenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sendLoginLink(state, action: PayloadAction<IUserLogin>) {
      state.loading = true;
    },
    fetchAuthenticatedUser(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setAuthentiactedUser(state, { payload }: PayloadAction<AuthUser>) {
      state.loading = false;
      state.isAuthenticated = true;
      state.authUser = payload;
      state.error = null;
    },
    resetAuthState(state) {
      state.isAuthenticated = false;
      state.authUser = undefined;
    },
    logoutUser(state) {
      state.loading = true;
    },
    error(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = AuthenticationSlice;
