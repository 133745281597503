import { createSelector } from 'reselect';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (s: RootState) => s.agentsContainer || initialState;

// eslint-disable-next-line
const getAgentId = ({ }, agentId: number) => agentId;

// eslint-disable-next-line
const getAgentInvitationId = ({ }, invitationid: number) => invitationid;

export const selectLoading = createSelector(
  [selectDomain],
  ({ loading }) => loading,
);
export const selectError = createSelector([selectDomain], ({ error }) => error);

export const selectAgents = createSelector(
  [selectDomain],
  ({ agents, filteredAgents }) => (filteredAgents ? filteredAgents : agents),
);

export const selectCheckedAgents = createSelector(
  [selectDomain],
  ({ agents }) => agents.filter(agent => agent.isAgentSelected === true),
);

export const selectAgentInvitations = createSelector(
  [selectDomain],
  ({ agentInvitations }) => agentInvitations,
);

export const getAgentInvitationById = createSelector(
  selectAgentInvitations,
  getAgentInvitationId,
  (invitations, invitationId) => invitations.find(i => i.id === invitationId),
);

export const getSelectedAgentInvitations = createSelector(
  [selectDomain],
  ({ agentInvitations }) =>
    agentInvitations.filter(
      agentInvitation => agentInvitation.isAgentSelected === true,
    ),
);

export const allAgentsSelected = createSelector(
  [selectDomain],
  ({ allAgentsSelected }) => allAgentsSelected,
);

export const allAgentInvitationsSelected = createSelector(
  [selectDomain],
  ({ allAgentInvitationsSelected }) => allAgentInvitationsSelected,
);

export const disableInvitationActionButtons = createSelector(
  [selectDomain],
  ({ agentInvitations }) =>
    !agentInvitations.some(agentInvitation => agentInvitation.isAgentSelected),
);

export const disableActionButtons = createSelector(
  [selectDomain],
  ({ agents }) => !agents.some(agent => agent.isAgentSelected),
);

export const selectAgentTags = createSelector(
  [selectDomain],
  ({ agentTags }) => agentTags,
);

export const selectedAgents = createSelector([selectDomain], ({ agents }) =>
  agents.filter(agent => agent.isAgentSelected),
);

export const selectUsers = createSelector([selectDomain], ({ users }) => users);

export const selectActiveAgent = createSelector(
  [selectDomain],
  ({ activeAgent }) => {
    if (activeAgent) {
      const meetingsWithDate = activeAgent.agentAdminMeetings.filter(
        meeting => meeting.startsAt,
      );
      const meetingsWithoutDate = activeAgent.agentAdminMeetings.filter(
        meeting => !meeting.startsAt,
      );

      const meetingsWithDateSorted = meetingsWithDate.slice().sort((a, b) => {
        let dateA = a.startsAt
          ? new Date(a.startsAt).getTime()
          : new Date(8640000000000000).getTime();
        let dateB = b.startsAt
          ? new Date(b.startsAt).getTime()
          : new Date(8640000000000000).getTime();
        return dateB - dateA;
      });

      const meetingsWithoutDateSorted = meetingsWithoutDate
        .slice()
        .sort((a, b) => {
          let idA = a.id ? a.id : 0;
          let idB = b.id ? b.id : 0;
          return idB - idA;
        });

      const agent = {
        ...activeAgent,
        agentAdminMeetings: [
          ...meetingsWithoutDateSorted,
          ...meetingsWithDateSorted,
        ],
      };
      return agent;
    }
    return activeAgent;
  },
);

export const selectAgentEventStatistics = createSelector(
  [selectDomain],
  ({ eventStatistics }) => eventStatistics,
);

export const selectAgentLeadsStatistics = createSelector(
  [selectDomain],
  ({ leadsStatistics }) => {
    if (leadsStatistics) {
      leadsStatistics = {
        ...leadsStatistics,
        monthlyStats: leadsStatistics.monthlyStats.map(m => ({
          count: m.count,
          month: m.month.toLowerCase(),
        })),
      };
    }
    return leadsStatistics;
  },
);

export const selectAgentCandidatesStatistics = createSelector(
  [selectDomain],
  ({ candidatesStatistics }) => {
    if (candidatesStatistics) {
      candidatesStatistics = {
        ...candidatesStatistics,
        monthlyStats: candidatesStatistics.monthlyStats.map(m => ({
          count: m.count,
          month: m.month.toLowerCase(),
        })),
      };
    }
    return candidatesStatistics;
  },
);

export const selectNewTag = createSelector(
  [selectDomain],
  ({ newTag }) => newTag,
);

export const selectFilterOptions = createSelector(
  [selectDomain],
  ({ filterOptions }) => filterOptions,
);

export const selectDeletedAgentInvitation = createSelector(
  [selectDomain],
  ({ deletedAgentInvitation }) => deletedAgentInvitation,
);

export const selectAgentsLocations = createSelector(
  [selectDomain],
  ({ agentsLocations }) => agentsLocations,
);

export const selectAddedBatchTags = createSelector(
  [selectDomain],
  ({ addedBatchTags }) => addedBatchTags,
);

export const selectAgentInvitation = createSelector(
  [selectDomain],
  ({ agentInvitation }) => agentInvitation,
);

export const selectAgentInvitationReminder = createSelector(
  [selectDomain],
  ({ sentAgentReminder }) => sentAgentReminder,
);

export const selectAddedTagsFromList = createSelector(
  [selectDomain],
  ({ addedTagsFromList }) => addedTagsFromList,
);

export const selectAgentsCompanies = createSelector(
  [selectDomain],
  ({ agentsCompanies }) => agentsCompanies,
);

export const selectActiveView = createSelector(
  [selectDomain],
  ({ activeView }) => activeView,
);
