export interface User {
  id?: number;
  name: string;
  email: string;
  imageUrl: string;
  phone: string;
  emailFooter: string;
  insertedAt: string;
  updatedAt: string;
  imageBase64: string | null;
}

//for redux
export type UsersState = {
  users: Array<User>;
  newUserCreated?: boolean;
};

export type UsersListProps = {
  users: Array<User>;
};

export interface UserFormProps {
  saveAdminUser: Function;
}

export type UserPayload = Pick<
  User,
  'id' | 'name' | 'email' | 'imageUrl' | 'phone' | 'emailFooter' | 'imageBase64'
>;

export interface UserFormParams {
  userId: string;
}

export enum ROLES {
  ADMIN = 0,
  AGENT = 1,
  CANDIDATE = 2,
}

export interface IUserLogin {
  email: string;
  role: number;
}
export type UserLoginPayload = Pick<IUserLogin, 'email' | 'role'>;
