import { IAgentTag } from './agents';
import { CandidateGroupEntry } from './candidate';

export type JobsState = {
  jobsWithCount: Array<IJobWithCount>;
  job?: IJob;
  updatedJob?: IJob;
  failedTransitionJob?: { transition: string; jobId: number; jobTitle: string };
  jobPacks?: Array<IJobPack>;
  selectedJobId?: number;
  jobPack?: IJobPack;
  jobPackEdit?: IJobPackEdit;
  newJobTag?: IJobTag;
  jobTags?: Array<IJobTag>;
  emailRecipients: Array<CandidateGroupEntry>;
  marketJob?: IMarketJob;
  candidateSendout?: boolean;
  redirectJobs?: boolean;
};

export interface IJobTag extends IAgentTag {
  jobTaggingId?: number;
}

export type JobListProps = {
  jobWithCount: IJobWithCount;
};

export type JobStateLabelProps = {
  jobStatusValue: JobStatus;
};

export type IJob = {
  isJobSelected?: boolean;
  title: string;
  shortDescription: string;
  longDescription: string;
  keyResponsibilities: Array<string>;
  findersFee: string;
  companyName: string;
  companyLogoUrl: string;
  companyUrl: string;
  companyDescription: string;
  companyLogoEncoded: string;
  location: string;
  confidential: boolean;
  personalityTraits: Array<string>;
  skills: Array<string>;
  contactId: number;
  status: string;
  applicationLanguage: string;
  wentLiveAt: string;
  emailDescription: string;
  wentOfflineAt: string;
  shouldRerun: true;
  reruns: Array<string>;
  previewKey: string;
  customListTitle: string;
  customListGhostTitle: string;
  featured: true;
  internal: true;
  agentTagsIncluded?: Array<string>;
  agentTagsExcluded?: Array<string>;
  adminUser: {
    email: string;
    imageUrl: string;
    imageBase64: string;
    name: string;
    phone: string;
    emailFooter: string;
    id: number;
    insertedAt?: string;
    updatedAt?: string;
  };
  id: number;
  insertedAt?: string;
  updatedAt?: string;
  jobTags?: IJobTag[];
};

export type INewJob = {
  title: string;
  shortDescription: string;
  longDescription: string;
  emailDescription: string;
  findersFee: string;
  applicationLanguage: string;
  keyResponsibilities: Array<string>;
  personalityTraits: Array<string>;
  skills: Array<string>;
  companyName: string;
  companyUrl: string;
  companyLogoEncoded: string;
  companyLogoUrl?: string;
  companyDescription: string;
  location: string;
  confidential: boolean;
  featured: boolean;
  contactId?: number;
  status: string;
  agentTagsIncluded: Array<string>;
  agentTagsExcluded: Array<string>;
  customListTitle: string;
  customListGhostTitle: string;
  id?: number;
  wentLiveAt?: string;
  wentOfflineAt?: string;
  internal?: boolean;
  shouldRerun?: boolean;
  reruns?: Array<string>;
  previewKey?: string;
  jobTags?: IJobTag[];
};

export type JobFormProps = {
  createJob: Function;
  createNewTag: Function;
  updateJob: Function;
  createPreviewLink: Function;
  fetchJobById: Function;
  createNewJobTag: Function;
  fetchJobTags: Function;
};

export interface JobFormParams {
  jobId: string;
}

export type IJobWithCount = {
  id: number;
  title: string;
  companyName: string;
  status: JobStatus;
  shouldRerun: boolean;
  candidateCount: number;
  applicationCount: number;
  mostRecentChange: string;
  isJobSelected?: boolean;
  marketJobId?: number;
};

export enum JobStatus {
  Active = 'active',
  Previous = 'previous',
  Current = 'current',
  Closed = 'closed',
  Archived = 'archived',
  Draft = 'draft',
  Next = 'next',
  Ongoing = 'ongoing',
}

export enum JobState {
  Live = 'live',
  Ongoing = 'ongoing',
}

export enum JobPackStatus {
  Scheduled = 'scheduled',
  Released = 'released',
  Reminded = 'reminded',
  Retired = 'retired',
}

export type IJobPack = {
  releaseAt: string;
  remindAt: string;
  overrideRelease: boolean;
  customSubject: string;
  customHeader: string;
  customDescription: string;
  overrideReminder: boolean;
  customReminderSubject: string;
  customReminderHeader: string;
  customReminderDescription: string;
  releaseOpens?: number;
  uniqueReleaseOpens?: number;
  reminderOpens?: number;
  uniqueReminderOpens?: number;
  releaseSeeAllClicks?: number;
  uniqueReleaseSeeAllClicks?: number;
  reminderSeeAllClicks?: number;
  uniqueReminderSeeAllClicks?: number;
  totalReleaseSends?: number;
  totalReminderSends?: number;
  status?: JobPackStatus;
  jobPackEntries?: Array<IJobPackEntry>;
  id?: number;
  insertedAt?: string;
  updatedAt?: string;
  jobs?: Array<number>;
  releaseClicks?: number;
  uniqueReleaseClicks?: number;
  reminderClicks?: number;
  uniqueReminderClicks?: number;
};

export type IJobPackEntry = {
  jobTitle?: string;
  jobId: number;
  jobPackId?: number;
  releaseClicks?: number;
  reminderClicks?: number;
  uniqueReleaseClicks?: number;
  uniqueReminderClicks?: number;
  companyName?: string;
};

export interface UpdateJob {
  jobId: number;
  status: string;
}

export interface JobPackFormParams {
  jobPackId: string;
}

export interface IJobPackEdit {
  data: IJobPack;
  allJobs?: Array<IJob>;
}

export interface ISortOptions {
  label: string;
  keySelector: string;
}

export enum SortLabels {
  latest = 'Latest changes',
  name = 'Name',
  email = 'Email address',
}

const sortByLatestKey = 'latest';
const sortByNameKey = 'name';
const sortByEmailKey = 'email';

export const sortByOptions: Array<ISortOptions> = [
  {
    label: SortLabels[sortByLatestKey],
    keySelector: sortByLatestKey,
  },
  {
    label: SortLabels[sortByNameKey],
    keySelector: sortByNameKey,
  },
  {
    label: SortLabels[sortByEmailKey],
    keySelector: sortByEmailKey,
  },
];

export interface IMarketJob {
  sendOn: string;
  function: string[];
  experience: string[];
  overrideSettings: boolean;
  customSubject: string;
  customHeader: string;
  customDescription: string;
  jobId: number;
  id?: number;
}

export interface MarketJobParams {
  marketJobId: string;
}
